import { HProducts } from "../../../config/axios.context";
import { APP_PRODUCTS_TOPPING_SAVE, APP_PRODUCTS_TOPPING_UPDATE, 
    APP_PRODUCTS_TOPPING_STATUS, APP_PRODUCTS_TOPPING_GET_BY_PRODUCT, 
    APP_PRODUCTS_TOPPING_GET_BY_ID_PRODUCT   } from '../../../utils/apiurl';

export default  {
    /**
     * Funcion que permite guardar los datos de los toppings
     * @returns 
     */
  async save(data) {
        try {
            const url = APP_PRODUCTS_TOPPING_SAVE;
            return await HProducts.post(url, data);
        } catch (error) {
            return error.response.data;
        }
    },

    /**
     * Funcion que permite actualizar los datos del topping
     * @param {*} id ID del topping
     * @param {*} data Modelo de datos del topping
     * @returns 
     */
    async update(id, data) {
        try {
            const url = APP_PRODUCTS_TOPPING_UPDATE + id;
            return await HProducts.put(url, data);
        } catch (error) {
            return error.response.data;
        }
    },
    /**
     * Función que permite actualizar el status del topping
     * @param {*} id ID del Topping
     * @param {*} data Modelo de datos
     * @returns 
     */
    async status(id, data) {
        try {
            const url = APP_PRODUCTS_TOPPING_STATUS + id;
            return await HProducts.put(url, data);
        } catch (error) {
            return error.response.data;
        }
    },

    /**
     * Funcion que permite obtener los toppings por producto/servicio
     * @param {*} id 
     * @returns 
     */
    async getByProduct(id) {
        try {
            const url = APP_PRODUCTS_TOPPING_GET_BY_PRODUCT + id;
            return await HProducts.get(url);
        } catch (error) {
            return error.response.data;
        }
    },

    /**
     * Función que permite obtener los datos de un topping por ID e ID de Producto
     * @param {*} id 
     * @param {*} id_product 
     * @returns 
     */
    async getByIdProduct(id, id_product) {
        try {
            const url = APP_PRODUCTS_TOPPING_GET_BY_ID_PRODUCT + id + '/' + id_product;
            return await HProducts.get(url);
        } catch (error) {
            return error.response.data;
        }
    },


}