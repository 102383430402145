import menu from '../HProduct/menu/menu.service';
import schedule from '../HProduct/schedule/schedule.service';
import topping from '../HProduct/topping/topping.service';
import toppingvalue from '../HProduct/topping/toppingvalue.service';
import Services from '../HProduct/service/service.service';

export const MenuService = menu;
export const ScheduleService = schedule;
export const ToppingService = topping;
export const ToppingValueService = toppingvalue;
export const ServicesService = Services;