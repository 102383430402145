import { HProducts } from "../../../config/axios.context";
import { APP_PRODUCTS_TOPPING_VALUE_SAVE, APP_PRODUCTS_TOPPING_VALUE_UPDATE, 
    APP_PRODUCTS_TOPPING_VALUE_GET_BY_TOPPING, APP_PRODUCTS_TOPPING_VALUE_GET_BY_ID, 
    APP_PRODUCTS_TOPPING_VALUE_STATUS } from '../../../utils/apiurl';

export default  {
    /**
     * Funcion que permite guardar los valores del topping
     * @returns 
     */
    async save(data) {
        try {
            const url = APP_PRODUCTS_TOPPING_VALUE_SAVE;
            return await HProducts.post(url, data);
        } catch (error) {
            return error.response.data;
        }
    },
    /**
     * Función que permite actualizar los datos de un valor de topping
     * @param {*} id ID del valor
     * @param {*} data Modelo de datos del valor
     * @returns 
     */
    async update(id, data) {
        try {
            const url = APP_PRODUCTS_TOPPING_VALUE_UPDATE + id;
            return await HProducts.put(url, data);
        } catch (error) {
            return error.response.data;
        }
    },

    /**
     * Función que permite obtener los valores de un topping
     * @param {*} id ID del topping
     * @returns 
     */
    async getByTopping(id) {
        try {
            const url = APP_PRODUCTS_TOPPING_VALUE_GET_BY_TOPPING + id;
            return await HProducts.get(url);
        } catch (error) {
            return error.response.data;
        }
    },

    /**
     * Función que permite obtener los datos del valor del topping
     * @param {*} id ID del valor
     * @returns 
     */
    async getById(id) {
        try {
            const url = APP_PRODUCTS_TOPPING_VALUE_GET_BY_ID + id;
            return await HProducts.get(url);
        } catch (error) {
            return error.response.data;
        }
    },

    /**
     * Función que permite actualizar el status del valor
     * @param {*} id ID del valor
     * @param {*} data Modelo de datos del valor
     * @returns 
     */
    async status(id, data) {
        try {
            const url = APP_PRODUCTS_TOPPING_VALUE_STATUS + id;
            return await HProducts.put(url, data);
        } catch (error) {
            return error.response.data;
        }
    },


}