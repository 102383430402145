
import { HProducts } from "../../../config/axios.context";
import {APP_PRODUCTS_SERVICE_SAVE, APP_PRODUCTS_SERVICE_GET_ALL, APP_PRODUCTS_SERVICE_GET_BY_ID, APP_PRODUCTS_SERVICE_UPDATE, 
    APP_PRODUCTS_SERVICE_STATUS, APP_PRODUCT_SERVICE_COMPLEMENT_SUMMARY } from '../../../utils/apiurl';

export default {
    /**
     * Función que permite guardar los datos del servicio
     * @param {*} data Modelom de datos de servicio
     * @returns 
     */
     async save(data) {
        try {
            const url = APP_PRODUCTS_SERVICE_SAVE;
            return await HProducts.post(url, data);
        } catch (error) {
            return error.response.data;
        }
    },

    /**
     * Permite obtener los servicios por ID de categoría
     * @param {*} id_category ID de la categoría
     * @param {*} id_entity ID de la entidad
     * @param {*} page Pagina
     * @param {*} limit Limite
     * @returns 
     */
    async getByCategory(id_category, id_entity, page, limit) {
        try {
            const url = APP_PRODUCTS_SERVICE_GET_ALL + id_category + '/' + id_entity + "/" + page + "/" + limit + "/" + 1;
            return await HProducts.get(url);
        } catch (error) {
            return error.response.data;
        }
    },

    /**
     * Funcion que permite obtener el servicio por ID
     * @param {*} id ID del servicio
     * @returns 
     */
    async getById(id) {
        try {
            const url = APP_PRODUCTS_SERVICE_GET_BY_ID + id;
            return await HProducts.get(url);
        } catch (error) {
            return error.response.data;
        }
    },

    /**
     * Función que permite actualizar los datos de los servicios
     * @param {*} id ID del servicio
     * @param {*} data  Modelo de datos del servicio
     * @returns 
     */
    async update(id, data) {
        try {
            const url = APP_PRODUCTS_SERVICE_UPDATE + id;
            return await HProducts.put(url, data);
        } catch (error) {
            return error.response.data;
        }
    },

    /**
     * Función que permite actualizar el status de un servicio
     * @param {*} id ID del servicio
     * @param {*} data Datos a actualizar
     * @returns 
     */
    async status(id, data) {
        try {
            const url = APP_PRODUCTS_SERVICE_STATUS + id;
            return await HProducts.put(url, data);
        } catch (error) {
            return error.response.data;
        }
    },

     /**
     * Función que permite obtener los complementos de un producto/menu/servicio
     * @param {*} id ID del producto
     * @returns 
     */
         async getComplementSummary(id) {
            try {
                const url = APP_PRODUCT_SERVICE_COMPLEMENT_SUMMARY + id;
                return await HProducts.get(url);
            } catch (error) {
                return error.response.data;
            }
        },
    

}